import { CheckCircleIcon } from "@heroicons/react/solid";
import React from "react";

interface PillProps {
  on: boolean;
  toggle: any;
  icon: string;
  text: string;
  badge?: string;
}

export default function Pill({ on, toggle, icon, text, badge }: PillProps) {
  const colors = on ? "bg-blue-100 text-blue-600" : "bg-gray-100 text-gray-600";

  return (
    <div
      className={`${colors} ${
        on ? "pl-0.5 pr-2" : "px-2.5"
      } rounded-full py-0.5 ml-1 mb-1 flex items-center text-sm-medium cursor-pointer relative`}
      onClick={toggle}
    >
      {on ? (
        <CheckCircleIcon className="h-4 w-4 sm:h-6 sm:w-6 mr-0.5" />
      ) : (
        <></>
      )}

      {text}
      {badge && badge === "new" && (
        <span className="ml-1 text-green-600 font-extrabold">NEW</span>
      )}
    </div>
  );
}
