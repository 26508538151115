import { useGoogleLogin } from "@react-oauth/google";
import { useContext } from "react";
import { DesktopContext } from "../contexts/DesktopContextProvider/DesktopContextProvider";
import {
  updateGmbInfoDatabase,
  getGmbTokens,
  storeGoogleTokens,
} from "../components/primaryrender/connections/ConnectionsTab/utils";
import { getNewGmbReviews } from "../components/primaryrender/reviews/ReviewContainer/utils";
import { useRouter } from "next/router";
import { syncGmb } from "../components/primaryrender/google/utils";
import { useTestimonials } from "./useTestimonalsInfo/useTestimonalsInfo";

export function useAuthGoogle(slug: any) {
  const {
    setIsGmbAuthenticated,
    profileInfo,
    setProfileInfo,
    basicInfo,
    setBasicInfo,
    setReviewInfo,
    setGmbVerificationStatus,
    setGmbAccessToken,
    setGmbEmail,
  } = useContext(DesktopContext);
  const { mutateTestimonialInfo } = useTestimonials(profileInfo);

  const router = useRouter();

  async function authenticateGmb(tokenObj: any) {
    try {
      const accessToken = tokenObj?.access_token;

      if (accessToken) {
        await storeGoogleTokens(tokenObj, slug);
        setGmbAccessToken(accessToken);

        const urlOrigin = window.location.pathname;
        localStorage.setItem("googleConnectOrigin", urlOrigin);

        router.push("/google-connect");
        return;
      }

      return "failure";
    } catch (e) {
      return "failure";
    }
  }

  async function saveGoogleInfo(gmbLocationId: string) {
    try {
      const response = await updateGmbInfoDatabase(slug);
      const responseStatus = response?.status;
      const updatedGmbData = response?.data?.message;

      if (responseStatus !== 201) {
        setIsGmbAuthenticated(false);
        return;
      }

      const verificationStatus =
        updatedGmbData?.gmb_location_verification_status;
      setGmbVerificationStatus(verificationStatus);
      setGmbEmail(updatedGmbData?.gmb_email);

      if (verificationStatus === "verified") {
        setBasicInfo({
          ...basicInfo,
          ...updatedGmbData,
          gmb_posts_toggle: true,
          gmb_photos_toggle: true,
          gmb_business_info_toggle: true,
          gmb_services_toggle: true,
        });

        syncGmb(slug);
      } else {
        setBasicInfo({
          ...basicInfo,
          ...updatedGmbData,
        });
      }

      if (profileInfo) {
        setProfileInfo({
          ...profileInfo,
          gmb_location_id: gmbLocationId,
        });
      }

      if (verificationStatus === "verified") {
        await getNewGmbReviews(basicInfo?.sitePk);
        await mutateTestimonialInfo();
      }
    } catch (e) {
      console.log(e, "error saving gmb data in database on google auth");
    }
  }

  const startGoogleLogin = useGoogleLogin({
    onSuccess: (response) => gmbExchangeCodeForTokens(response),
    onError: () => console.log("Login Failed"),
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/userinfo.email",
  });

  async function gmbExchangeCodeForTokens(response: any) {
    const code = response?.code;
    const { sitePk } = basicInfo;
    const tokensObj = await getGmbTokens(code, sitePk);
    await authenticateGmb(tokensObj);
  }

  return {
    startGoogleLogin,
    saveGoogleInfo,
  } as any;
}
