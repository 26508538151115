import React, { useContext, useEffect, useState } from "react";

import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";
import { storeDatabaseToggleStatus } from "../../components/primaryrender/editor/utils";
import ActionModal from "../../components/common/ActionModal/ActionModal";
import MenuSwitch from "../../components/uiwrappers/MenuSwitch/MenuSwitch";
import { useAuthGoogle } from "../useAuthGoogle";
import { facebookToast, instagramToast } from "../utils";
import { useAuthInstagram } from "../useAuthInstagram";

import Pill from "../../components/common/Pill/Pill";
import { useAuthFacebook } from "../useAuthFacebook";
import { GOOGLE_VERIFY_COPY } from "./constants";

export function useSyncSocials(toggle?: string) {
  // We pass in which page we're on ^^ and set states within this hook based on that
  const {
    profileInfo,
    basicInfo,
    setBasicInfo,
    isGmbAuthenticated,
    isFacebookAuthenticated,
    isInstagramAuthenticated,
    gmbVerificationStatus,
  } = useContext(DesktopContext);
  const { slug } = profileInfo || {};

  const { connectToFacebook } = useAuthFacebook(slug);

  const { startGoogleLogin } = useAuthGoogle(slug);
  const { connectToInstagram, renderInstagramErrorConnectionModal } =
    useAuthInstagram();

  const {
    gmb_photos_toggle,
    gmb_business_info_toggle,
    gmb_posts_toggle,
    gmb_services_toggle,
    fb_photos_toggle,
    fb_business_info_toggle,
    fb_posts_toggle,
    ig_photos_toggle,
    ig_posts_toggle,
  } = basicInfo || {};

  useEffect(() => {
    if (basicInfo) {
      // This switch determines which column in the databse we should read/update when a social toggle is toggled
      switch (toggle) {
        case "photos":
          setStoredGoogleToggleValue(gmb_photos_toggle);
          setGoogleToggleColumn("gmb_photos_toggle");
          setStoredFacebookToggleValue(fb_photos_toggle);
          setFacebookToggleColumn("fb_photos_toggle");
          setStoredInstagramToggleValue(ig_photos_toggle);
          setInstagramToggleColumn("ig_photos_toggle");
          break;
        case "business_info":
          setStoredGoogleToggleValue(gmb_business_info_toggle);
          setGoogleToggleColumn("gmb_business_info_toggle");
          setStoredFacebookToggleValue(fb_business_info_toggle);
          setFacebookToggleColumn("fb_business_info_toggle");
          break;
        case "posts":
          setStoredGoogleToggleValue(gmb_posts_toggle);
          setGoogleToggleColumn("gmb_posts_toggle");
          setStoredFacebookToggleValue(fb_posts_toggle);
          setFacebookToggleColumn("fb_posts_toggle");
          setStoredInstagramToggleValue(ig_posts_toggle);
          setInstagramToggleColumn("ig_posts_toggle");
          break;
        case "services":
          setStoredGoogleToggleValue(gmb_services_toggle);
          setGoogleToggleColumn("gmb_services_toggle");
          break;
        default:
          break;
      }
    }
  }, [basicInfo]);

  // States that hold which columns in the database to update when a social toggle is toggled
  const [googleToggleColumn, setGoogleToggleColumn] = useState<string>("");
  const [facebookToggleColumn, setFacebookToggleColumn] = useState<string>("");
  const [instagramToggleColumn, setInstagramToggleColumn] =
    useState<string>("");
  // States that hold the database values of the social toggles so we can set the toggles according to what's in the database
  const [storedGoogleToggleValue, setStoredGoogleToggleValue] =
    useState<boolean>(false);
  const [storedFacebookToggleValue, setStoredFacebookToggleValue] =
    useState<boolean>(false);
  const [storedInstagramToggleValue, setStoredInstagramToggleValue] =
    useState<boolean>(false);

  // Whether or not this toggle is going to render as "on" or "off"
  const [toggleGoogleStatus, setToggleGoogleStatus] = useState<boolean>(false);
  const [toggleFacebookStatus, setToggleFacebookStatus] =
    useState<boolean>(false);
  const [toggleInstagramStatus, setToggleInstagramStatus] =
    useState<boolean>(false);
  const [editPostToggleFacebookStatus, setEditPostToggleFacebookStatus] =
    useState<boolean>(false);
  const [editPostToggleGoogleStatus, setEditPostToggleGoogleStatus] =
    useState<boolean>(false);

  const [isFacebook, setIsFacebook] = useState<boolean>(false);
  const [isInstagram, setIsInstagram] = useState<boolean>(false);

  const [unverifiedLocationModal, setUnverifiedLocationModal] = useState(false);
  const [pendingLocationModal, setPendingLocationModal] = useState(false);
  const [suspendedLocationModal, setSuspendedLocationModal] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [
    noProfessionalInstagramAccountErrorModal,
    setNoProfessionalInstagramAccountErrorModal,
  ] = useState(true);
  const [instagramLearnModal, setInstagramLearnModal] = useState(false);

  const [error, setError] = useState({
    header: "",
    message: "",
    buttonText: "",
    buttonAction: () => {},
  });

  function openFacebookModal() {
    setIsFacebook(true);
    setErrorModalOpen(true);
    setError({
      header: "Connect to Facebook to use this feature",
      message:
        "You’re missing out on free website traffic and our best features.",
      buttonText: "Connect",
      buttonAction: async (response) => {
        const status = await connectToFacebook(response);
        setErrorModalOpen(false);
        setIsFacebook(false);
        facebookToast(status);
      },
    });
  }

  function openInstagramModal() {
    setIsInstagram(true);
    setErrorModalOpen(true);
    setError({
      header: "Connect to Instagram to use this feature",
      message:
        "You’re missing out on free website traffic and our best features.",
      buttonText: "Connect",
      buttonAction: async (response) => {
        const status = await connectToInstagram(response);
        setErrorModalOpen(false);
        setIsInstagram(false);
        instagramToast(status);
      },
    });
  }

  useEffect(() => {
    if (toggle === "posts" || toggle === "photos") {
      setToggleFacebookStatus(
        isFacebookAuthenticated && storedFacebookToggleValue
      );
    } else {
      setToggleFacebookStatus(
        isFacebookAuthenticated && storedFacebookToggleValue
      );
    }
  }, [storedFacebookToggleValue]);

  useEffect(() => {
    setToggleInstagramStatus(
      isInstagramAuthenticated && storedInstagramToggleValue
    );
  }, [storedInstagramToggleValue, isInstagramAuthenticated]);

  // These useEffects set the toggles to "true" (the default value of the toggle columns in the database)
  useEffect(() => {
    const toggleGoogleStatusOn =
      isGmbAuthenticated &&
      storedGoogleToggleValue &&
      gmbVerificationStatus === "verified";

    setToggleGoogleStatus(toggleGoogleStatusOn);
  }, [storedGoogleToggleValue, isGmbAuthenticated, gmbVerificationStatus]);

  useEffect(() => {
    if (toggle === "posts" || toggle === "photos") {
      if (isFacebookAuthenticated)
        setToggleFacebookStatus(storedFacebookToggleValue);
    } else {
      if (isFacebookAuthenticated)
        setToggleFacebookStatus(storedFacebookToggleValue);
    }
  }, [isFacebookAuthenticated, profileInfo?.current_plan]);

  useEffect(() => {
    if (!errorModalOpen) {
      setTimeout(() => setIsFacebook(false), 500);
      setTimeout(() => setIsInstagram(false), 500);
    }
  }, [errorModalOpen]);

  async function handleToggleUploadLocation(location?: any) {
    if (location === "facebook") {
      if (isFacebookAuthenticated) {
        storeDatabaseToggleStatus(facebookToggleColumn, profileInfo?.slug);
        setToggleFacebookStatus(!toggleFacebookStatus);
        setBasicInfo({
          ...basicInfo,
          [facebookToggleColumn]: !toggleFacebookStatus,
        });
      } else {
        openFacebookModal();
      }
    }

    if (location === "instagram") {
      if (isInstagramAuthenticated) {
        storeDatabaseToggleStatus(instagramToggleColumn, profileInfo?.slug);
        setToggleInstagramStatus(!toggleInstagramStatus);
        setBasicInfo({
          ...basicInfo,
          [instagramToggleColumn]: !toggleInstagramStatus,
        });
      } else {
        openInstagramModal();
      }
    }

    if (location === "google") {
      if (gmbVerificationStatus) {
        if (gmbVerificationStatus !== "verified") {
          if (gmbVerificationStatus === "unverified") {
            setUnverifiedLocationModal(true);
          } else if (gmbVerificationStatus === "pending") {
            setPendingLocationModal(true);
          } else if (gmbVerificationStatus === "suspended") {
            setSuspendedLocationModal(true);
          }
        } else {
          if (isGmbAuthenticated) {
            storeDatabaseToggleStatus(googleToggleColumn, profileInfo?.slug);
            setToggleGoogleStatus(!toggleGoogleStatus);
            setBasicInfo({
              ...basicInfo,
              [googleToggleColumn]: !toggleGoogleStatus,
            });
          } else {
            setErrorModalOpen(true);
            setError({
              header: "Connect to Google to use this feature",
              message:
                "You’re missing out on free website traffic and our best features.",
              buttonText: "Connect",
              buttonAction: async () => {
                startGoogleLogin();
                setErrorModalOpen(false);
              },
            });
          }
        }
      } else {
        setErrorModalOpen(true);
        setError({
          header: "Connect to Google to use this feature",
          message:
            "You’re missing out on free website traffic and our best features.",
          buttonText: "Connect",
          buttonAction: async () => {
            startGoogleLogin();
            setErrorModalOpen(false);
          },
        });
      }
    }
  }

  function renderSyncSwitches(origin?: string) {
    return (
      <div className="flex justify-start pr-1">
        {toggle !== "services" && origin !== "facebook" && (
          <MenuSwitch
            on={toggleFacebookStatus}
            toggle={() => handleToggleUploadLocation("facebook")}
            icon={
              <div className="text-gray-400 mr-2">
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            }
            type="facebook"
          />
        )}
        {origin !== "google" && (
          <MenuSwitch
            on={toggleGoogleStatus}
            toggle={() => handleToggleUploadLocation("google")}
            icon={
              <div className="text-gray-400 ml-4 mr-2">
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                  fill="currentColor"
                >
                  <path d="M 15.003906 3 C 8.375 3 3 8.371094 3 15 C 3 21.628906 8.375 27 15.003906 27 C 25.015625 27 27.269531 17.707031 26.328125 13 L 15 13 L 15 17 L 22.738281 17 C 21.847656 20.449219 18.726562 23 15 23 C 10.582031 23 7 19.417969 7 15 C 7 10.582031 10.582031 7 15 7 C 17.007812 7 18.839844 7.746094 20.246094 8.96875 L 23.085938 6.128906 C 20.953125 4.183594 18.117188 3 15.003906 3 Z M 15.003906 3 " />
                </svg>
              </div>
            }
            type="google"
          />
        )}
      </div>
    );
  }

  function renderSyncPills() {
    return (
      <div className="flex justify-start -ml-1">
        <Pill
          on={toggleFacebookStatus}
          toggle={() => handleToggleUploadLocation("facebook")}
          icon="facebook"
          text="Facebook"
        />
        <Pill
          on={toggleGoogleStatus}
          toggle={() => handleToggleUploadLocation("google")}
          icon="google"
          text="Google"
        />
        <Pill
          on={toggleInstagramStatus}
          toggle={() => handleToggleUploadLocation("instagram")}
          icon="instagram"
          text="Instagram"
          badge="new"
        />
      </div>
    );
  }

  function renderSyncEditPostPills() {
    function editPostToggle(platform: string) {
      if (platform === "facebook") {
        setEditPostToggleFacebookStatus(!editPostToggleFacebookStatus);
      } else if (platform === "google") {
        setEditPostToggleGoogleStatus(!editPostToggleGoogleStatus);
      }
    }

    return (
      <div className="flex justify-start -ml-1">
        <Pill
          on={editPostToggleFacebookStatus}
          toggle={() => editPostToggle("facebook")}
          icon="facebook"
          text="Facebook"
        />
        <Pill
          on={editPostToggleGoogleStatus}
          toggle={() => editPostToggle("google")}
          icon="google"
          text="Google"
        />
      </div>
    );
  }

  function renderSyncFacebook() {
    return (
      <div className="flex justify-start -ml-1">
        <Pill
          on={toggleFacebookStatus}
          toggle={() => handleToggleUploadLocation("facebook")}
          icon="facebook"
          text="Facebook"
        />
      </div>
    );
  }

  function renderSyncModals() {
    return (
      <div>
        <ActionModal
          open={unverifiedLocationModal}
          setOpen={setUnverifiedLocationModal}
          header="Verify your Google Business Profile to use this feature"
          subheader={GOOGLE_VERIFY_COPY}
          primaryButtonText="Verify"
          primaryButtonFunction={() =>
            window.open("https://business.google.com/locations", "_blank")
          }
          icon=""
          hideCancel={true}
        />
        <ActionModal
          open={pendingLocationModal}
          setOpen={setPendingLocationModal}
          header="Try again once verified"
          subheader="Your Google Business Profile is in the process of being verified. Once verified, you will be able to use this feature."
          primaryButtonText="Okay"
          primaryButtonFunction={() => setPendingLocationModal(false)}
          icon=""
          hideCancel={true}
        />
        <ActionModal
          open={suspendedLocationModal}
          setOpen={setSuspendedLocationModal}
          header="Google has suspended your Business Profile"
          subheader="In order to use this feature, you’ll need to work with Google to re-approve your account."
          primaryButtonText="Learn How"
          primaryButtonFunction={() =>
            window.open(
              "https://help.toplinepro.com/en/articles/8677617-google-suspended-your-business-profile-now-what",
              "_blank"
            )
          }
          icon=""
          hideCancel={true}
        />
        <ActionModal
          open={errorModalOpen}
          setOpen={setErrorModalOpen}
          header={error?.header}
          subheader={error?.message}
          primaryButtonText={error?.buttonText}
          primaryButtonFunction={error?.buttonAction}
          icon="connect"
          facebook={isFacebook}
          instagram={isInstagram}
        />
        {renderInstagramErrorConnectionModal()}
      </div>
    );
  }

  return {
    toggleGoogleStatus,
    setToggleGoogleStatus,
    toggleFacebookStatus,
    toggleInstagramStatus,
    setToggleFacebookStatus,
    editPostToggleFacebookStatus,
    setEditPostToggleFacebookStatus,
    editPostToggleGoogleStatus,
    setEditPostToggleGoogleStatus,
    renderSyncSwitches,
    renderSyncModals,
    renderSyncPills,
    renderSyncEditPostPills,
    renderSyncFacebook,
    handleToggleUploadLocation,
    setError,
    setErrorModalOpen,
    openFacebookModal,
    setNoProfessionalInstagramAccountErrorModal,
  } as const;
}
