import axios from "axios";
import { formatPhoneNumber } from "../contacts/helpers/formatPhoneNumber";
import { api } from "../../../helpers/topline-api";
import { IAddress, ICriticalFields } from "./types";

const MAX_CATEGORIES = 3;

export function createEmptyCriticalFields() {
  const emptyCriticalFields: ICriticalFields = {
    businessCategory: {
      displayName: "",
      initialValue: "",
      gmbValue: "",
      compareFormatFn: (v) => v.toLowerCase(),
    },
    businessName: {
      displayName: "",
      initialValue: "",
      gmbValue: "",
      compareFormatFn: (v) => v.toLowerCase(),
    },
    phoneNumber: {
      displayName: "",
      initialValue: "",
      gmbValue: "",
      compareFormatFn: (v) => v.toLowerCase(),
    },
    address: {
      displayName: "",
      initialValue: "",
      gmbValue: "",
      compareFormatFn: (v) => v.toLowerCase(),
    },
  };
  return emptyCriticalFields;
}

export function getMismatchedFields(criticalFields: ICriticalFields) {
  const mismatchedFields: string[] = [];
  Object.entries(criticalFields).forEach(([key, fieldObj]) => {
    const gmbValue = fieldObj.gmbValue
      ? fieldObj.compareFormatFn(fieldObj.gmbValue)
      : "";
    const initialValue = fieldObj.initialValue
      ? fieldObj.compareFormatFn(fieldObj.initialValue)
      : "";
    if (initialValue !== gmbValue) {
      mismatchedFields.push(key);
    }
  });
  return mismatchedFields;
}

export async function syncGmb(slug: string) {
  try {
    await api.get(`/api/update-gmb-on-connection-to-google/${slug}`);
  } catch (e) {
    console.log("syncGmb error", e);
  }
}

export async function getGmbAccountIds(slug: string) {
  try {
    const response = await api.post(`/api/google-get-account-ids/${slug}`);
    return response?.data?.message;
  } catch (e) {
    console.log("getGmbAccountIds error", e);
    return [];
  }
}

export async function getGmbLocations(slug: string, gmbAccountIds: any[]) {
  try {
    const response = await api.post(`/api/google-get-all-locations/${slug}`, {
      gmbAccountIds,
    });
    return response?.data?.message;
  } catch (e) {
    console.log("getGmbLocations error", e);
    return [];
  }
}

export function formatAddress(addressObj: Address) {
  if (addressObj.hasOwnProperty("addressLines")) {
    // Google Business Profile (GBP) address format
    const { addressLines, locality, administrativeArea, postalCode } =
      addressObj;
    const streetAddress = addressLines.join(" ");
    const city = locality;
    const state = administrativeArea;
    const zipCode = postalCode;
    return `${streetAddress}, ${city}, ${state}, ${zipCode}`;
  } else {
    // Regular address format
    const { mailingAddress, city, state, zipcode } = addressObj;
    return `${mailingAddress}, ${city}, ${state}, ${zipcode}`;
  }
}

export async function getVerificationStatus(slug: string) {
  try {
    const response = await api.get(
      `/api/google-get-verification-status/${slug}`
    );
    return response?.data?.message;
  } catch (e) {
    console.log("getVerificationStatus error", e);
    return "";
  }
}

export async function getVerificationOptions(
  slug: string,
  businessContext: any
) {
  try {
    const response = await api.post(
      `/api/google-get-verification-options/${slug}`,
      { businessContext }
    );
    return response?.data?.message;
  } catch (e) {
    console.log("getVerificationOptions error:", e);
    return [];
  }
}

export function getBusinessContext(zipcode) {
  const businessContext = {
    languageCode: "en",
    context: {
      address: {
        regionCode: "US",
        postalCode: zipcode,
      },
    },
  };
  return businessContext;
}

export async function getGmbEmailAddress(slug) {
  try {
    const response = await api.get(`/api/google-get-gmb-email/${slug}`);
    return response?.data?.message;
  } catch (e) {
    console.log("error:", e);
    return {};
  }
}

export async function getGmbCategories(query, accessToken) {
  try {
    let encodedQuery;
    if (typeof query === "string") {
      encodedQuery = query.replace(/\s/g, "_");
    } else {
      encodedQuery = query?.displayName.replace(/\s/g, "_");
    }
    const url = `https://mybusinessbusinessinformation.googleapis.com/v1/categories?regionCode=US&languageCode=en&filter=displayName=${encodedQuery}&pageSize=50&view=BASIC&access_token=${accessToken}`;
    const response = await axios.get(url);
    return response?.data;
  } catch (e) {
    console.log("error:", e);
    return "";
  }
}

export async function getSuggestedGmbCategories(): Promise<string> {
  try {
    const response = await api.get(`api/google-get-suggested-gmb-industries`);
    const suggestedCategories = response?.data?.message;
    // Show only the first MAX_CATEGORIES suggested categories if more than MAX_CATEGORIES exist
    return suggestedCategories.splice(0, MAX_CATEGORIES).join(", ");
  } catch (e) {
    console.log("Error: ", e);
    return "";
  }
}

export async function createGmbProfile(
  slug,
  gmbAccountId,
  businessInfo,
  businessType
) {
  try {
    const response = await api.post(`/api/google-create-gbp/${slug}`, {
      gmbAccountId,
      businessInfo,
      businessType,
    });
    return response?.data?.message;
  } catch (e) {
    console.log("error:", e);
    return { error: true };
  }
}

export async function uploadGMBDocuments(slug: string, files: File[]) {
  try {
    const response = await api.postForm(`api/google-upload-documents/${slug}`, {
      gmb_document: files,
    });
    return response;
  } catch (e) {
    console.log("error:", e);
    return { error: true };
  }
}

export async function updateGMBProfile(
  slug: string,
  phone: string,
  title: string,
  address: IAddress,
  categoryName: string,
  categoryDisplayName: string
) {
  try {
    const response = await api.patch(`/api/google-update-gbp/${slug}`, {
      phone,
      title,
      address,
      category_name: categoryName,
      category_display_name: categoryDisplayName,
    });
    return response?.data?.message;
  } catch (e) {
    console.log("error:", e);
    return { error: true };
  }
}

export async function verifyGmb(slug, verificationInfo) {
  try {
    const response = await api.post(`/api/google-verify-gbp/${slug}`, {
      verificationInfo,
    });
    return response?.data?.message;
  } catch (error) {
    console.log("error get service areas:", error);
    return {};
  }
}

export async function completeGmbVerification(
  slug,
  verificationId,
  sixDigitCode
) {
  try {
    const code = {
      pin: sixDigitCode,
    };
    const response = await api.post(
      `/api/google-complete-verification/${slug}`,
      { verificationId, code }
    );
    return response?.data?.message;
  } catch (error) {
    console.log("error get service areas:", error);
    return {};
  }
}

export async function storeGmbAddress(
  slug: string,
  streetAddress: string,
  city: string,
  state: string,
  zipCode: string,
  country: string
) {
  try {
    const response = await api.post(`/api/store-google-address/${slug}`, {
      streetAddress,
      city,
      state,
      zipCode,
      country,
    });
    return response?.data?.message;
  } catch (error) {
    console.log("error get service areas:", error);
    return {};
  }
}

export function getBusinessInfo(companyName, phone, category) {
  const businessInfo = {
    title: companyName,
    languageCode: "en",
    categories: {
      primaryCategory: {
        name: category?.name,
      },
    },
    phoneNumbers: {
      primaryPhone: phone,
    },
  };

  return businessInfo;
}

export const googleButtonStyle =
  "flex justify-center py-2.5 bg-blue-600 text-white rounded-sm shadow-sm cursor-pointer";

export const inactiveGoogleButtonStyle =
  "flex justify-center py-2.5 bg-gray-200 text-gray-500 rounded-sm shadow-sm cursor-not-allowed";

export const canadianProvinces = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
];

export const usStates = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export function getGmbCategory(industry: string) {
  try {
    const formattedIndustry = industry?.toLowerCase();
    if (formattedIndustry && gbpCategoryMap.hasOwnProperty(formattedIndustry)) {
      const mappedIndustry = gbpCategoryMap[formattedIndustry];
      return mappedIndustry;
    }
  } catch (e) {
    return "";
  }
}

export const gbpCategoryMap = {
  masonry: {
    name: "categories/gcid:masonry_contractor",
    displayName: "Masonry contractor",
  },
  concrete: {
    name: "categories/gcid:concrete_contractor",
    displayName: "Concrete contractor",
  },
  "construction & exterior remodeling": {
    name: "categories/gcid:construction_company",
    displayName: "Construction company",
  },
  fencing: {
    name: "categories/gcid:fence_contractor",
    displayName: "Fence contractor",
  },
  roofing: {
    name: "categories/gcid:roofing_contractor",
    displayName: "Roofing contractor",
  },
  restoration: {
    name: "categories/gcid:general_contractor",
    displayName: "General contractor",
  },
  "carpet cleaning": {
    name: "categories/gcid:carpet_cleaning_service",
    displayName: "Carpet cleaning service",
  },
  "land clearing": {
    name: "categories/gcid:excavating_contractor",
    displayName: "Excavating contractor",
  },
  "pressure washing": {
    name: "categories/gcid:pressure_washing_service",
    displayName: "Pressure washing service",
  },
  "property management company": {
    name: "categories/gcid:property_management_company",
    displayName: "Property management company",
  },
  "in-home service": {
    name: "categories/gcid:general_contractor",
    displayName: "General contractor",
  },
  "automotive repair shop": {
    name: "categories/gcid:auto_repair_shop",
    displayName: "Auto repair shop",
  },
  "auto detailing": {
    name: "categories/gcid:car_detailing_service",
    displayName: "Car detailing service",
  },
  painting: {
    name: "categories/gcid:painting",
    displayName: "Painting",
  },
  "residential cleaning": {
    name: "categories/gcid:house_cleaning_service",
    displayName: "House cleaning service",
  },
  "tree services": {
    name: "categories/gcid:tree_service",
    displayName: "Tree service",
  },
  "landscaping & snow removal": {
    name: "categories/gcid:landscaper",
    displayName: "Landscaper",
  },
  "lawn care": {
    name: "categories/gcid:landscaper",
    displayName: "Landscaper",
  },
  "junk removal": {
    name: "categories/gcid:garbage_collection_service",
    displayName: "Garbage collection service",
  },
  "irrigation services": {
    name: "categories/gcid:landscaper",
    displayName: "Landscaper",
  },
  "garage door technician": {
    name: "categories/gcid:general_contractor",
    displayName: "General contractor",
  },
  "pressure washing & soft washing": {
    name: "categories/gcid:pressure_washing_service",
    displayName: "Pressure washing service",
  },
  "construction & remodeling": {
    name: "categories/gcid:remodeler",
    displayName: "Remodeler",
  },
  "landscaping & hardscaping": {
    name: "categories/gcid:landscaper",
    displayName: "Landscaper",
  },
  "painting, staining, & home renovations": {
    name: "categories/gcid:painting",
    displayName: "Painting",
  },
  "residential & commercial cleaning": {
    name: "categories/gcid:commercial_cleaning_service",
    displayName: "Commercial cleaning service",
  },
};

export function extractPhoneNumber(verificationOptions) {
  try {
    const option = verificationOptions.find(
      (o) =>
        o?.verificationMethod === "PHONE_CALL" ||
        o?.verificationMethod === "SMS"
    );
    return formatPhoneNumber(option?.phoneNumber);
  } catch (e) {
    return "";
  }
}

export function extractEmailAddress(verificationOptions) {
  try {
    const option = verificationOptions.find(
      (o) => o?.verificationMethod === "EMAIL"
    );
    return `${option?.emailData?.user}@${option?.emailData?.domain}`;
  } catch (e) {
    return "";
  }
}

function formatMailAddress(
  addressLines,
  locality,
  administrativeArea,
  postalCode
) {
  const mailAddress = [
    addressLines?.join(", ") || "",
    locality,
    administrativeArea,
    postalCode,
  ]
    .join(", ")
    .trim();

  return mailAddress;
}

export function extractMailInfo(verificationOptions) {
  try {
    const option = verificationOptions.find(
      (o) => o?.verificationMethod === "ADDRESS"
    );
    const { addressLines, locality, administrativeArea, postalCode } =
      option?.addressData?.address;

    const mailAddress = formatMailAddress(
      addressLines,
      locality,
      administrativeArea,
      postalCode
    );

    const estDeliveryTime = option?.addressData?.expectedDeliveryDaysRegion;
    return { mailAddress, estDeliveryTime };
  } catch (e) {
    return { mailAddress: "", estDeliveryTime: "" };
  }
}

export function checkForAddressErrors(addressErrors: any, address: any) {
  const { mailingAddress, city, state, zipcode } = address;

  if (!mailingAddress?.trim()) {
    addressErrors = { ...addressErrors, mailingAddress: true };
  }
  if (!city?.trim()) {
    addressErrors = { ...addressErrors, city: true };
  }
  if (!state?.trim()) {
    addressErrors = { ...addressErrors, state: true };
  }
  if (!zipcode?.trim()) {
    addressErrors = { ...addressErrors, zipcode: true };
  }

  return addressErrors;
}

export function isAutoMethod(verificationOptions: any[]) {
  if (
    verificationOptions?.length > 0 &&
    verificationOptions[0]?.verificationMethod === "AUTO"
  ) {
    return true;
  }
  return false;
}

export const GMB_BUSINESS_TYPES = {
  SERVICE_BUSINESS: "service-business",
  LOCAL_STOREFRONT: "local-storefront",
};

export const GMB_BUSINESS_TYPES_BOTH = "both";
