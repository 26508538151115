import React, { useContext, useState } from "react";
import ActionModal from "../../components/common/ActionModal/ActionModal";
import { useRouter } from "next/router";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";

function useChurnModal() {
  const [openChurnModal, setOpenChurnModal] = useState(false);
  const router = useRouter();
  const { profileInfo, proHasChurned } = useContext(DesktopContext);

  function shouldOpenChurnModal() {
    if (proHasChurned) {
      setOpenChurnModal(true);
      return true;
    }
    return false;
  }
  function renderChurnModal() {
    const handleManagePlanClick = () => {
      router.push("/settings/billing?editSubscription=true");
      setOpenChurnModal(false);
    };

    const handleCloseModal = () => {
      setOpenChurnModal(false);
    };

    return (
      <ActionModal
        open={openChurnModal}
        setOpen={setOpenChurnModal}
        hideCancel={false}
        header={
          <div>
            Your{" "}
            <span className="capitalize">
              {profileInfo?.latestCanceledPlan?.type}
            </span>{" "}
            plan is no longer active.
          </div>
        }
        subheader="Reactivate your plan to unlock this feature."
        primaryButtonText={"Manage Plan"}
        primaryButtonFunction={handleManagePlanClick}
        secondaryButtonText={"No Thanks"}
        secondaryButtonFunction={handleCloseModal}
        icon="lock"
        warning={true}
      />
    );
  }
  return {
    shouldOpenChurnModal,
    renderChurnModal,
    openChurnModal,
    setOpenChurnModal,
  };
}

export default useChurnModal;
