export const GOOGLE_CHARACTER_COUNT_ERROR =
  "Google does not allow posts to contain more than 1500 characters. Please edit post or select different platforms.";
export const NO_PLATFORMS_SELECTED_ERROR =
  "Please select at least 1 platform to post.";
export const GOOGLE_DIGITAL_MEDIA_ERROR =
  "Google do not allow logos or text in post images. Please edit your attachments or select different platforms.";
export const GOOGLE_VIDEO_ERROR =
  "Google does not allow videos in posts. Please edit your attachments or select different platforms.";
export const GOOGLE_MULTIPLE_PHOTOS_ERROR =
  "Google does not allow multiple photos in posts. Please edit your attachments or select different platforms.";
export const GOOGLE_PHOTO_VIDEO_ERROR =
  "Google does not allow multiple photos or videos in posts. Please edit your attachments or select different platforms.";
export const FACEBOOK_PHOTO_VIDEO_ERROR =
  "Facebook does not allow multiple videos or both photos and a video in posts. Please edit your attachments or select different platforms.";
export const FACEBOOK_GROUPS_PHOTO_VIDEO_ERROR =
  "Facebook does not allow multiple videos or both photos and a video in posts. Please edit your attachments.";
export const GENERAL_PHOTO_VIDEO_ERROR =
  "Facebook and Google do not allow multiple videos or both photos and a video in posts. Please edit your attachments or select different platforms.";
export const INSTAGRAM_CONTENT_ERROR =
  "Image or video required when posting to Instagram.";
export const GOOGLE_PHONE_NUMBER_ERROR =
  "Google does not allow post content to include a phone number. Please edit post or select different platforms.";
export const GOOGLE_VERIFY_COPY =
  "To verify your profile, Google will send a code via text, call you with a code, or ask you to record a video.";
