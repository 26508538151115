import React, { Dispatch, SetStateAction } from "react";
import { Switch } from "@headlessui/react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface MenuSwitchProps {
  on: boolean;
  toggle: Dispatch<SetStateAction<boolean>> | ((_value: boolean) => void);
  leftLabel?: string; //already here
  customStyleLeftLabel?: string;
  rightLabel?: string;
  description?: string;
  padding?: string;
  loading?: boolean;
  icon?: React.ReactElement | null;
  type?: string;
  rightSubLabel?: string;
  testId?: string;
}

export default function MenuSwitch({
  on,
  toggle,
  leftLabel,
  customStyleLeftLabel,
  rightLabel,
  rightSubLabel,
  description,
  padding,
  loading,
  icon,
  type,
  testId,
}: MenuSwitchProps) {
  return (
    <Switch.Group
      as="div"
      className={`flex items-center justify-between ${padding}`}
    >
      {(leftLabel || description) && (
        <span className="flex flex-col">
          <Switch.Label
            as="span"
            className={`text-sm-medium text-gray-500 ${customStyleLeftLabel} `}
            passive
          >
            {leftLabel && leftLabel}
          </Switch.Label>
          <Switch.Description
            as="span"
            className="text-sm-normal text-gray-500"
          >
            {description}
          </Switch.Description>
        </span>
      )}
      <div className="flex items-center">
        {loading && <LoadingSpinner color="blue" />}
        {icon && icon}
        <Switch
          checked={on}
          onChange={toggle}
          data-cy={`switch-${type ? type : "toggle"}-${on ? "on" : "off"}`}
          data-testid={testId}
          className={classNames(
            on ? "bg-blue-600" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-all ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              on ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
        {rightLabel && (
          <Switch.Label as="span" className="ml-3">
            <span className="text-sm-medium text-gray-900">{rightLabel}</span>
            <span className="text-sm-normal text-gray-500 ml-2">
              {rightSubLabel}
            </span>
          </Switch.Label>
        )}
      </div>
    </Switch.Group>
  );
}
